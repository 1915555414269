import gql from 'graphql-tag';

export const MeetingNotesSettingsQuery = gql`
	query MeetingNotesSettingsQuery($siteId: ID!) {
		loom_settings(siteId: $siteId) {
			meetingNotesAvailable
			meetingNotesEnabled
		}
	}
`;
