import type { FC } from 'react';
import React, { Fragment, memo } from 'react';

import { useQuery } from '@atlassian/ufo-apollo-log/use-query';

import { PerformanceStart, PERFORMANCE_SUBJECT_mainLayout } from '@confluence/performance';
import { markErrorAsHandled } from '@confluence/graphql';
import { ErrorDisplay, isNotFoundError, isUnauthorizedError } from '@confluence/error-boundary';
import { useIsEmbeddedConfluence_DO_NOT_USE } from '@confluence/embedded-confluence-check';
import { AutomatedMeetingNotesSpotlightWrapper } from '@confluence/automated-meeting-notes';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';

import type { MainLayoutComponentProps } from './MainLayoutComponent';
import { MainLayoutComponent } from './MainLayoutComponent';
import { MainLayoutQuery } from './MainLayoutQuery.graphql';
import type {
	MainLayoutQuery as QueryType,
	MainLayoutQueryVariables as QueryVariables,
} from './__types__/MainLayoutQuery';

type MainLayoutProps = Pick<
	MainLayoutComponentProps,
	'children' | 'enableNavigation' | 'navView' | 'isViewPage' | 'isEdit' | 'editorPreloader'
> & {
	spaceKey?: string;
};

export const MainLayout: FC<MainLayoutProps> = memo(
	({ spaceKey, isEdit, enableNavigation, isViewPage, ...otherProps }) => {
		const isEmbeddedConfluence = useIsEmbeddedConfluence_DO_NOT_USE();
		const isLivePage = useIsCurrentPageLive();

		const { data, error: unhandledError } = useQuery<QueryType, QueryVariables>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			MainLayoutQuery,
			{
				variables: {
					// When spaceKey is undefined/falsy the query is skipped, so defaulting it to
					// an empty string doesn't change the logic but merely keeps TypeScript happy.
					spaceKey: spaceKey || '',
				},
				skip: !spaceKey || isEdit || isLivePage,
			},
		);

		let error = unhandledError;
		if (isNotFoundError(error) || isUnauthorizedError(error)) {
			// The MainLayoutQuery only loads the space styling configuration, and neither error nor
			// missing configuration don't break the user experience. Unauthorized or NotFound error
			// (anonymous user, restricted or missing space/page) are handled by children components
			// of MainLayout and are part of the normal user flow, hence these can be safely muted.
			markErrorAsHandled(error);
			error = undefined;
		}

		return (
			<Fragment>
				{error && <ErrorDisplay error={error} />}
				<PerformanceStart subject={PERFORMANCE_SUBJECT_mainLayout} />
				<MainLayoutComponent
					{...otherProps}
					enableNavigation={isEmbeddedConfluence ? false : enableNavigation}
					screen={data?.space?.lookAndFeel?.content?.screen ?? undefined}
					isViewPage={isViewPage}
					isEdit={isEdit}
				/>
				<AutomatedMeetingNotesSpotlightWrapper />
			</Fragment>
		);
	},
);
