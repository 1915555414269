/**
 * Parse connect app scripts width and height, and preset connect apps dimention
 * before iframe gets injected, to avoid connect apps iframe causing layout shift
 * up and down multiple times
 *
 * Return total height of connect apps rendered in a location
 * @param container - The HTMLElement contains connect apps
 * @returns {number | undefined} - Returns total height of connect apps rendered in the container
 */
export const presetConnectAppsWrapperDimension = (container) => {
	if (!document || !container) {
		return;
	}
	// Get all connect-app scripts at container, there can be multiple ones
	const allScripts = container.querySelectorAll('script.ap-iframe-body-script');
	if (!allScripts || allScripts.length === 0) {
		return;
	}
	// Total height of connect-app wrapper
	let totalHeight = 0;

	const parsePx = (matchedVal) => {
		// Parse % or px
		let addPx = '';
		if (matchedVal.indexOf('%') === -1 && matchedVal.indexOf('px') === -1) {
			addPx = 'px';
		}
		return addPx;
	};

	allScripts.forEach((iframeScript) => {
		// Get connect-app script html
		const scriptHtml = iframeScript?.innerHTML || '';

		if (scriptHtml) {
			const scriptWrapper = iframeScript.parentNode;

			// Extract connect-app height
			const matchedHeight = scriptHtml.match(/"h":\s*"(\d+(?:%|px)?)"/);
			const matchedHeightVal = matchedHeight?.[1];
			if (matchedHeightVal) {
				const matchedInt = parseInt(matchedHeightVal);
				// Add this connect-app height to total height
				if (typeof matchedInt === 'number') {
					totalHeight += matchedInt;
				}
				// Preset connect-app wrapper height before iframe is injected
				if (scriptWrapper) {
					scriptWrapper.style.height = matchedHeightVal + parsePx(matchedHeightVal);
				}
			} else {
				// If unable to extract height value, default to 0 during iframe loading to prevent jumping,
				// this will eventually be removed after connect apps are fully loaded.
				scriptWrapper.style.height = '0px';
			}

			//Extract connect-app width
			const matchedWidth = scriptHtml.match(/"w":\s*"(\d+(?:%|px)?)"/);
			const matchedWidthVal = matchedWidth?.[1];
			if (matchedWidthVal) {
				// Preset connect-app wrapper height before iframe is injected
				if (scriptWrapper) {
					scriptWrapper.style.width = matchedWidthVal + parsePx(matchedWidthVal);
				}
			}
		}
	});
	return totalHeight;
};

/**
 * Remove preset dimension after connect apps are loaded
 *
 * @param container - The HTMLElement contains connect apps
 * @returns {boolean | undefined} - Return if all connect apps are loaded
 */
export const removeConnectAppsWrapperDimension = (container, timeout = false) => {
	if (!document || !container) {
		return;
	}
	const appStatus: boolean[] = [];

	const allScripts = container.querySelectorAll('script.ap-iframe-body-script');
	if (!allScripts || allScripts.length === 0) {
		return;
	}

	// Remove each connect-app wrapper preset width and height after iframe loaded
	allScripts.forEach((iframeScript) => {
		// Get connect-app parent
		const scriptWrapper = iframeScript.parentNode;
		const iframeLoaded = !!scriptWrapper.querySelector('iframe');
		const statusIndicator = scriptWrapper.querySelector('.ap-status-indicator');
		const timeoutIndicator = scriptWrapper.querySelector('.ap-load-timeout');
		const statusIndicatorHidden = statusIndicator?.style.display === 'none';
		// Check connect app loading status
		const isAppLoaded = iframeLoaded && (statusIndicatorHidden || timeoutIndicator);
		// Store each iframe's loading status
		appStatus.push(isAppLoaded);

		// If iframe is loaded, remove preset width and height
		if (isAppLoaded || timeout) {
			if (scriptWrapper?.style.height) {
				scriptWrapper.style.height = '';
			}
			if (scriptWrapper?.style.width) {
				scriptWrapper.style.width = '';
			}
		}
	});

	// Return if all connect apps are loaded
	return appStatus.every((status) => status === true);
};

// Max wait time to remove preset dimension
export const MAX_WAIT_TIME_REMOVE_DIMENSION = 10000;
