/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useLayoutEffect, useRef, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import debounce from 'lodash/debounce';

import { ATL_GENERAL } from '@confluence/web-item-location';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { usePageState } from '@confluence/page-context';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { useRouteDataRef } from '@confluence/route-manager';
import { isPageOrBlogPost } from '@confluence/content-types-utils';
import { fg } from '@confluence/feature-gating';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

import type {
	useATLGeneralWebPanelObserverSpaceThemeQuery as useATLGeneralWebPanelObserverSpaceThemeQuery$data,
	useATLGeneralWebPanelObserverSpaceThemeQueryVariables as useATLGeneralWebPanelObserverSpaceThemeQuery$variables,
} from './__types__/useATLGeneralWebPanelObserverSpaceThemeQuery';
import { useWebPanelLocation } from './useWebPanelLocation';
import {
	presetConnectAppsWrapperDimension,
	removeConnectAppsWrapperDimension,
	MAX_WAIT_TIME_REMOVE_DIMENSION,
} from './breakoutUtils';

export const useATLGeneralWebPanelObserver = (isViewPage: boolean) => {
	const [{ contentId, spaceKey, contentType }] = usePageState();
	const { panels } = useWebPanelLocation({
		location: ATL_GENERAL,
		fetchPolicy: 'cache-first',
		spaceKey,
		contentId,
	});
	const fixedATLGeneralWebPanelRef = useRef(null);
	const allAppsLoaded = useRef(false);
	const isLivePage = useIsCurrentPageLive();
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const routeDataRef = useRouteDataRef();
	const renderServerPlaceholder = useRenderServerPlaceholder();

	// We are expanding atl.general panel to full width when the side nav is not present. This is the case for
	// company hub and when url has mode=global, such as on custom homepage
	// TODO: For Nav 4, we want to replace these checks so that we simply check for existence of the side nav (DISCO-2142)
	const isFullWidth =
		isCompanyHubSpaceKey(spaceKey) || routeDataRef.current?.queryParams?.mode === 'global';

	const shouldShowATLGeneralWebPanel = panels.length > 0;

	// Determines if panels should not display a UI element and only allow for the support of scripts and analytics
	const shouldHidePanels = !isPageOrBlogPost(contentType) && contentType;

	// If the other conditions for a fixed web panel are true, check whether the space is themed
	// The web panel should not be fixed for themed spaces since they do not have a fixed page header
	const canFixATLGeneralWebPanel = shouldShowATLGeneralWebPanel && isViewPage && !isLivePage;
	const { data } = useQuery<
		useATLGeneralWebPanelObserverSpaceThemeQuery$data,
		useATLGeneralWebPanelObserverSpaceThemeQuery$variables
	>(
		gql`
			query useATLGeneralWebPanelObserverSpaceThemeQuery($spaceKey: String) {
				space(key: $spaceKey) {
					id
					theme {
						themeKey
					}
				}
			}
		`,
		{
			skip: !canFixATLGeneralWebPanel,
			variables: { spaceKey },
		},
	);

	const isATLGeneralWebPanelFixed = canFixATLGeneralWebPanel && data?.space?.theme === null;

	// ResizeObserver is needed because the height of web panel items can change at any time
	const resizeObserver = useMemo(() => {
		if (!renderServerPlaceholder) {
			if (fg('confluence_preset_atl_general_height')) {
				const callback = debounce(([entry]) => {
					if (entry) {
						// Remove each connect app preset dimension until all apps are loaded
						if (!allAppsLoaded.current) {
							allAppsLoaded.current = !!removeConnectAppsWrapperDimension(entry.target);
						}

						// Only reset container height after all connect apps are loaded,
						// to prevent layout shift jumping up and down
						if (allAppsLoaded.current) {
							document.documentElement.style.setProperty(
								'--atl-general-webpanel-height',
								`${entry.target.clientHeight}px`,
							);
							document.dispatchEvent(new CustomEvent('atlGeneralWebPanelHeightUpdated'));
						}
					}
				}, 100);
				return new ResizeObserver(callback);
			}
			return new ResizeObserver(([entry]) => {
				if (entry) {
					document.documentElement.style.setProperty(
						'--atl-general-webpanel-height',
						`${entry.target.clientHeight}px`,
					);
					document.dispatchEvent(new CustomEvent('atlGeneralWebPanelHeightUpdated'));
				}
			});
		}
	}, [renderServerPlaceholder]);

	useLayoutEffect(() => {
		const fixedATLGeneralWebPanel = fixedATLGeneralWebPanelRef.current;
		let appLoadTimeoutId;
		if (
			!renderServerPlaceholder &&
			isATLGeneralWebPanelFixed &&
			fixedATLGeneralWebPanel &&
			fg('confluence_preset_atl_general_height')
		) {
			// Handle content types that have web panel hidden, some content types have web panel hidden,
			// so it don't need to preset wrapper dimension.
			if (shouldHidePanels) {
				document.documentElement.style.setProperty('--atl-general-webpanel-height', `0px`);
				document.dispatchEvent(new CustomEvent('atlGeneralWebPanelHeightUpdated'));
			} else {
				const totalHeight = presetConnectAppsWrapperDimension(fixedATLGeneralWebPanel);
				if (totalHeight) {
					document.documentElement.style.setProperty(
						'--atl-general-webpanel-height',
						`${totalHeight}px`,
					);
					document.dispatchEvent(new CustomEvent('atlGeneralWebPanelHeightUpdated'));
				}

				// If after max wait time, connect apps are still not loaded, then remove preset dimensions
				appLoadTimeoutId = setTimeout(() => {
					if (!allAppsLoaded.current) {
						removeConnectAppsWrapperDimension(fixedATLGeneralWebPanel, true);
						allAppsLoaded.current = true;
						const connectAppsWrapperContainer = fixedATLGeneralWebPanel as HTMLElement;
						const containerHeight = connectAppsWrapperContainer?.clientHeight || 0;
						document.documentElement.style.setProperty(
							'--atl-general-webpanel-height',
							`${containerHeight}px`,
						);
						document.dispatchEvent(new CustomEvent('atlGeneralWebPanelHeightUpdated'));
					}
				}, MAX_WAIT_TIME_REMOVE_DIMENSION);
			}
		}

		if (isATLGeneralWebPanelFixed) {
			fixedATLGeneralWebPanel && resizeObserver?.observe(fixedATLGeneralWebPanel);
		}

		return () => {
			if (isATLGeneralWebPanelFixed) {
				fixedATLGeneralWebPanel && resizeObserver?.unobserve(fixedATLGeneralWebPanel);
			}
			if (appLoadTimeoutId) {
				clearTimeout(appLoadTimeoutId);
			}
		};
	}, [
		shouldShowATLGeneralWebPanel,
		isATLGeneralWebPanelFixed,
		resizeObserver,
		renderServerPlaceholder,
		shouldHidePanels,
	]);

	return {
		shouldShowATLGeneralWebPanel,
		isATLGeneralWebPanelFixed,
		fixedATLGeneralWebPanelRef,
		isCompanyHub,
		isFullWidth,
		shouldHidePanels,
	};
};
