import React from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { Global, css } from '@emotion/core';

import { token } from '@atlaskit/tokens';

export type ContentBodyProps = {
	topElementStyle: object;
	shouldShowAside?: boolean;
	bannerHeight: number;
};

export type MainContentContainerProps = {
	isEnableRightSidebarEnabled: boolean;
	disableMinWidth: boolean;
	isNav4Enabled?: boolean;
};

export type PanelWrapperProps = {
	showPanel?: boolean;
};

export type FixedPanelWrapperProps = PanelWrapperProps & {
	isFullWidth?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MainContentContainer = styled.div<MainContentContainerProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEnableRightSidebarEnabled, disableMinWidth, isNav4Enabled }) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: isEnableRightSidebarEnabled
			? `calc(
    100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) -
      var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px)
  - ${token('space.025')}) !important`
			: isNav4Enabled
				? '100%'
				: `calc(100% - ${token('space.025')})`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		marginLeft: isNav4Enabled ? token('space.0') : token('space.025'),
		transition: 'width 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
		flexFlow: 'row wrap',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minWidth: !isEnableRightSidebarEnabled && !disableMinWidth ? 700 : '',
		'@media print': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			width: '100% !important',
		},
	}),
);

MainContentContainer.displayName = 'MainContentContainer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const WebPanelWrapper = styled.div<PanelWrapperProps>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	display: props?.showPanel ? 'block' : 'none',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const FixedWebPanelWrapper = styled.div<FixedPanelWrapperProps>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	display: props?.showPanel ? 'block' : 'none',
	zIndex: 12, // needs to be above 10 so it covers floating sticky table rows
	width: '100%',
	position: 'fixed',
	backgroundColor: token('elevation.surface'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	left: props?.isFullWidth ? '0' : 'var(--leftSidebarWidth)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: props?.isFullWidth
		? 'none'
		: 'calc( 100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) - var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px) )',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const FixedWebPanelSpacer = styled.div(() => ({
	height: 'var(--atl-general-webpanel-height)',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentBodyWrapper = styled.div({
	marginTop: 0,
	boxSizing: 'border-box',
	clear: 'both',
	position: 'relative',
	width: '100%',
	display: 'flex',
	flex: '0 1 auto',
	minHeight: 'unset',
});

const contentBodyDefaults: () => object = () => ({
	background: token('elevation.surface'),
	boxSizing: 'border-box',
	verticalAlign: 'top',
	width: '100%',
	minHeight: 'initial',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ContentBody = styled.div<ContentBodyProps>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...contentBodyDefaults(),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...props.topElementStyle,
	padding: 0,
	width: '100%',
}));


// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ContentBodyWithContainerType = styled.div<ContentBodyProps>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...contentBodyDefaults(),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...props.topElementStyle,
	padding: 0,
	width: '100%',
	containerType: 'inline-size',
	containerName: 'content-body-container',
}));

// TODO: remove this when confluence-previews no longer used
export const GlobalImagePreviewStyling = () => (
	<Global
		// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
		styles={css`
			.cp-container[role='dialog'] {
				z-index: 350;
			}
		`}
	/>
);
