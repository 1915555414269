import React from 'react';
import { presetConnectAppsWrapperDimension } from '@confluence/web-panel-location/entry-points/breakoutConsts';

declare global {
	interface Window {
		__RENDERER_BYPASS_BREAKOUT_SSR__?: boolean;
	}
}

/**
 * Inline Script that updates breakout node width on client side,
 * before main JavaScript bundle is ready.
 *
 * More info: https://product-fabric.atlassian.net/wiki/spaces/E/pages/1216218119/Renderer+SSR+for+Breakout+Nodes
 */
export function BreakoutSSRInlineScript({ noOpSSRInlineScript }: { noOpSSRInlineScript: boolean }) {
	/**
	 * Should only inline this script while SSR,
	 * not needed on the client side.
	 */
	// For hydrateRoot there is a mismatch on client for this script.
	// So we want to add the script on client side but guard it with check to
	// not execute logic
	if (typeof window !== 'undefined' && !window.navigator.userAgent.includes('jsdom')) {
		if (!noOpSSRInlineScript) {
			return null;
		} else {
			window.__RENDERER_BYPASS_BREAKOUT_SSR__ = true;
		}
	}
	const context = createBreakoutInlineScript();
	return (
		<script
			// To investigate if we can replace this.
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{ __html: context }}
		></script>
	);
}

export function createBreakoutInlineScript() {
	return `
  (function(window){
		if(typeof window !== 'undefined' && window.__RENDERER_BYPASS_BREAKOUT_SSR__) {
			return;
		}
		${breakoutInlineScriptContext};
    (${applyBreakoutAfterSSR.toString()})(breakoutConstsInner);
  })(window);
 `;
}

export const breakoutInlineScriptContext = `
	var breakoutConstsInner = {}; 
  breakoutConstsInner.presetConnectAppsWrapperDimension = ${presetConnectAppsWrapperDimension.toString()};
`;

export function applyBreakoutAfterSSR(breakoutConstsInner) {
	const breakoutContainer = document.querySelector(
		"[data-connect-container='fixed-atl-general-wrapper']",
	);
	if (!breakoutContainer) {
		return;
	}
	const totalHeight = breakoutConstsInner?.presetConnectAppsWrapperDimension(breakoutContainer);
	if (totalHeight) {
		document.documentElement.style.setProperty('--atl-general-webpanel-height', `${totalHeight}px`);
		document.dispatchEvent(new CustomEvent('atlGeneralWebPanelHeightUpdated'));
	}
}
